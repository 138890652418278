import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import { AdvancedMarker, InfoWindow, useMap } from '@vis.gl/react-google-maps';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setModalOpen, setModalRestaurant } from '../../redux/features/infoModal/infoModalSlice';

const GoogleMapMarkers = () => {
    const [hoveredMarker, setHoveredMarker] = useState(null);
    const favoritedRestaurants = useSelector(
        (state) => state.favoritesReducer.favoriteRestaurants
    );
    const visitedRestaurants = useSelector(
        (state) => state.visitedReducer.visitedRestaurants
    );
    const wishlistRestaurants = useSelector(state => state.wishlistReducer.wishlistRestaurants);
    const restaurantMarkers = useSelector((state) => state.markersReducer.markers);
    const [clusterMarkers, setClusterMarkers] = useState({});
    const dispatch = useDispatch();
    const map = useMap();

    const handleMarkerMouseOver = (marker) => {
        setHoveredMarker(marker);
    };

    const handleMarkerClick = (marker) => {
        dispatch(setModalOpen(true));
        dispatch(setModalRestaurant(marker));
    };


    const getClusterStyles = (count) => {
        if (count < 8) {
            return {
                url: "/cluster-min.png",
                height: 50,
                width: 50,
            };
        }

        if (count < 25) {
            return {
                url: "/cluster-med.png",
                height: 60,
                width: 60,
            };
        }

        return {
            url: "/cluster-max.png",
            height: 70,
            width: 70,
        };
    }

    const clusterer = useMemo(() => {
      if (!map) return null;

      const renderer = {
        render: function ({ count, position }) {
                // use d3-interpolateRgb to interpolate between red and blue
                // create marker using svg icon
                const clusterStyles = getClusterStyles(count);

                return new window.google.maps.Marker({
                    position,
                    icon: {
                        url: clusterStyles.url,
                        scaledSize: new window.google.maps.Size(clusterStyles.width, clusterStyles.height),
                    },
                    label: {
                        text: String(count),
                        color: "rgba(255,255,255,0.9)",
                        fontSize: "12px",
                    },
                });
            }
      };
      // Optiosn for new marker clusterer
      return new MarkerClusterer({map, renderer, algorithm: new SuperClusterAlgorithm({
        radius: 200, // cluster size
        minPoints: 5,   // Minimum number of points to form a cluster
        maxZoom: 20,    // Maximum zoom level to cluster points
        minZoom: 0,     // Minimum zoom to create clusters
      }) });
    }, [map]);

    useEffect(() => {
      if (!clusterer) return;

      clusterer.clearMarkers();
      clusterer.addMarkers(Object.values(clusterMarkers));
    }, [clusterer, clusterMarkers]);


    const setMarkerRef = useCallback((marker, key) => {
        if (!marker && !clusterMarkers[key]) return;
        if (marker && clusterMarkers[key]) return;

        setClusterMarkers(prev => {
          if ((marker && prev[key]) || (!marker && !prev[key]))
            return prev;

          if (marker) {
            return {...prev, [key]: marker};
          } else {
            const {[key]: _, ...newMarkers} = prev;

            return newMarkers;
          }
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);


    const handleMarkerMouseOut = () => {
        setHoveredMarker(null);
    };

    const getMapsIcon = (marker) => {
        let mapObj = {};
        if (wishlistRestaurants.some((restaurantId) => restaurantId === marker.id)
        ) {
            mapObj.url = "/wish-icon.png";
            mapObj.scaledSize = new window.google.maps.Size(40, 40);
        } else if (
            favoritedRestaurants.some((restaurantId) => restaurantId === marker.id)
        ) {
            mapObj.url = "/fav-icon.png";
            mapObj.scaledSize = new window.google.maps.Size(40, 40);
        } else if (
            visitedRestaurants.some((restaurantId) => restaurantId === marker.id)
        ) {
            mapObj.url = "/visited-icon.png";
            mapObj.scaledSize = new window.google.maps.Size(30, 30);
        } else {
            mapObj.url = "/icon.png";
            mapObj.scaledSize = new window.google.maps.Size(30, 30);
        }

        return mapObj;
    };

    const handleClick = (marker) => {
        setHoveredMarker(null);
        handleMarkerClick(marker)
    }

    return (
            <>
            {restaurantMarkers.map((marker) => {
                const mapIcon = getMapsIcon(marker); // Use your existing function to get the icon properties
                return (
                    <AdvancedMarker
                        key={marker.id}
                        ref={(mk) => setMarkerRef(mk, marker.id)}
                        position={{lat: marker.latitude, lng: marker.longitude }}
                        clickable
                        onClick={() => handleClick(marker)}
                        onMouseEnter={() => handleMarkerMouseOver(marker)}
                        onMouseLeave={handleMarkerMouseOut}
                    >
                        <img
                            src={mapIcon.url}
                            alt="marker-icon"
                            width={mapIcon.scaledSize.width || 30} // Default width
                            height={mapIcon.scaledSize.height || 30} // Default height
                        />
                    </AdvancedMarker>
                )
            })}
            {hoveredMarker && (
                <InfoWindow
                    position={{ lat: hoveredMarker.latitude, lng: hoveredMarker.longitude }}
                    onCloseClick={() => setHoveredMarker(null)}
                    pixelOffset={[0, -30]}
                    maxWidth={200}
                >
                    <div>
                        {hoveredMarker.name}
                    </div>
                </InfoWindow>
            )}
            </>
    )
}

export default GoogleMapMarkers
