import { Tuple, combineReducers, configureStore  } from "@reduxjs/toolkit";
import authReducer from "./features/auth/authSlice";
import favoritesReducer from "./features/favorites/favoritesSlice";
import restaurantsReducer from "./features/restaurants/restaurantsSlice";
import visitedReducer from "./features/visited/visitedSlice";
import wishlistReducer from "./features/wishlist/wishlistSlice";
import markersReducer from "./features/markers/markersSlice";
import infoModalReducer from "./features/infoModal/infoModalSlice";

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Defaults to localStorage for web
import { thunk } from "redux-thunk";

const rootReducer = combineReducers({
  authReducer: authReducer,
  favoritesReducer: favoritesReducer,
  restaurantsReducer: restaurantsReducer,
  visitedReducer: visitedReducer,
  wishlistReducer: wishlistReducer,
  markersReducer: markersReducer,
  infoModalReducer: infoModalReducer
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['infoModalReducer', 'markersReducer', 'restaurantsReducer',  'visitedReducer', 'wishlistReducer', 'favoritesReducer']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: () => new Tuple(thunk),
});

export const persistor = persistStore(store);
