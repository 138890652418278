import React, { Fragment, useMemo, useState } from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    useMediaQuery,
    Box,
    IconButton,
    List,
    ListItem,
    Typography,
    Grid,
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import RestaurantIcon from '@mui/icons-material/Restaurant';
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import FavoriteIcon from "@mui/icons-material/Favorite";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import { FULL_HALAL } from "../RequestRestaurant/halal_status";
import { setModalOpen, setModalRestaurant } from "../../redux/features/infoModal/infoModalSlice";

export default function RestaurantDirectory({ open, handleClose }) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const user = useSelector((state) => state.authReducer.user);

    const restaurants = useSelector((state) => state.restaurantsReducer.restaurants);
    const favorites = useSelector((state) => state.favoritesReducer.favoriteRestaurants);
    const visited = useSelector((state) => state.visitedReducer.visitedRestaurants);
    const wishlist = useSelector(state => state.wishlistReducer.wishlistRestaurants);

    const [selectedCity, setSelectedCity] = useState("Calgary");

    const handleDialogClose = () => {
        handleClose();
    };

    const handleItemClick = (restaurant) => {
        dispatch(setModalRestaurant(restaurant));
        dispatch(setModalOpen(true));
    };

    const handleCityChange = (event) => {
        setSelectedCity(event.target.value);
    };

    // Filtering and sorting restaurants
    const sortedRestaurants = useMemo(() => {
        const filteredRestaurants = restaurants.filter((restaurant) => {
            const city = restaurant.city?.toUpperCase() ?? ""; // Safely handle undefined or null city
            if (selectedCity === "Calgary") return city.includes("CALGARY");
            if (selectedCity === "Edmonton") return city.includes("EDMONTON");
            if (selectedCity === "Other") return !city.includes("CALGARY") && !city.includes("EDMONTON");
            return true;
        });
        return filteredRestaurants.sort((a, b) => a.name.localeCompare(b.name));
    }, [restaurants, selectedCity]);

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleDialogClose}
                fullScreen={fullScreen}
                aria-labelledby="Restaurant-directory-title"
            >
                <DialogTitle id="Restaurant-directory-title">
                    Restaurant Directory
                    <IconButton
                        style={{ position: "absolute", top: 10, right: 10 }}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <FormControl component="fieldset">
                        <RadioGroup
                            row
                            value={selectedCity}
                            onChange={handleCityChange}
                            aria-label="city-filter"
                            name="city-filter"
                        >
                            <FormControlLabel value="Calgary" control={<Radio />} label="Calgary" />
                            <FormControlLabel value="Edmonton" control={<Radio />} label="Edmonton" />
                            <FormControlLabel value="Other" control={<Radio />} label="Other" />
                        </RadioGroup>
                    </FormControl>
                    <Typography variant="subtitle1" gutterBottom style={{ color: "#A9A9A9", fontWeight: "bold" }}>
                        Total Restaurants: {sortedRestaurants.length}
                    </Typography>
                    <List>
                        {sortedRestaurants.map((restaurant, index) => (
                            <ListItem
                                key={index}
                                disablePadding
                                sx={{
                                    "&:hover": { backgroundColor: "#f0f0f0" },
                                    borderBottom: "1px solid #ccc",
                                }}
                            >
                                <Button
                                    fullWidth
                                    onClick={() => handleItemClick(restaurant)}
                                    startIcon={<RestaurantIcon />}
                                    sx={{
                                        justifyContent: "flex-start",
                                        textTransform: "none",
                                        textAlign: "left",
                                        padding: "12px",
                                    }}
                                >
                                    <Grid item container direction="column">
                                        <Grid item container direction="row" alignItems="center">
                                            <Typography
                                                variant="body2"
                                                style={{ fontWeight: "bold", marginBottom: "4px" }}
                                            >
                                                {restaurant.name}
                                            </Typography>
                                            {user && (
                                                <Grid item sx={{ marginLeft: '0.5rem' }}>
                                                    {favorites.includes(restaurant.id) && (
                                                        <FavoriteIcon color="primary" style={{ fontSize: "1.2rem" }} />
                                                    )}
                                                    {visited.includes(restaurant.id) && (
                                                        <CheckCircleSharpIcon color="primary" style={{ fontSize: "1.2rem" }} />
                                                    )}
                                                    {wishlist.includes(restaurant.id) && (
                                                        <BookmarkAddIcon color="primary" style={{ fontSize: "1.2rem" }} />
                                                    )}
                                                </Grid>
                                            )}
                                        </Grid>
                                        <Typography style={{ fontSize: "0.8rem", color: "#666" }}>
                                            {restaurant.address}
                                        </Typography>
                                    </Grid>
                                    {restaurant.menu_status && (
                                        <Typography
                                            variant="body2"
                                            style={{
                                                fontSize: "0.8rem",
                                                fontWeight: "bold",
                                                color: restaurant.menu_status === FULL_HALAL ? "green" : "orange",
                                                marginLeft: "3rem",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            {restaurant.menu_status === FULL_HALAL ? "Full Halal" : "Partial Halal"}
                                        </Typography>
                                    )}
                                </Button>
                            </ListItem>
                        ))}
                    </List>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}>
                        <Button onClick={handleDialogClose} color="primary" variant="outlined">
                            Close
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
}
