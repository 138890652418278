import * as React from "react"
import {useEffect, useState} from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import {
    Autocomplete,
    Button,
    Chip,
    Grid,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    Select,
    TextField,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import {halalStates} from "../RequestRestaurant/halal_status"
import {collection, deleteDoc, doc, getDoc, getDocs, setDoc, updateDoc} from "firebase/firestore"
import {db, DB_PATHS} from "../../containers/Firebase/Firebase"
import {toast} from "react-toastify"
import DeleteIcon from '@mui/icons-material/Delete';
import CryptoJS from "crypto-js"
import {setRestaurants} from "../../redux/features/restaurants/restaurantsSlice"
import {setMarkers} from "../../redux/features/markers/markersSlice"
import {useDispatch} from "react-redux"
import {all_tags} from "../helpers/all_tags"
import TitleIcon from "@mui/icons-material/Title";
import { handleFetchPlaceData } from "../helpers/places_helper"
import { setModalOpen, setModalRestaurant, setPreviewMode } from "../../redux/features/infoModal/infoModalSlice"

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%", // Adjust the width as needed
    maxWidth: 500, // Set a maximum width
    maxHeight: "80vh", // Set a higher maximum height
    overflowY: "auto", // Enable vertical scrolling when content exceeds the maximum height
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};


export default function PendingRestaurantsModal({
                                                    open,
                                                    handleClose,
                                                    modalContent,
                                                    getPendingData,
                                                }) {
    const [editableContent, setEditableContent] = useState(modalContent);
    const dispatch = useDispatch();

    useEffect(() => {
        setEditableContent(modalContent)
    }, [modalContent])

    const handleInputChange = (event, field) => {
        setEditableContent({
            ...editableContent,
            [field]: event.target.value,
        })
    }

    const handleDelete = async () => {
        try {
            await deleteDoc(doc(db, DB_PATHS.REQUESTED, modalContent.id));
            toast.success(`Restaurant deleted successfully`);
            closeModal();
        } catch (error) {
            toast.error(`Error deleting document ${modalContent.id}: `)
            console.error(`Error deleting document ${modalContent.id}: `, error)
        }
    }

    const saveChanges = async () => {
        try {
            const requestedRef = doc(db, DB_PATHS.REQUESTED, modalContent.id);

            await updateDoc(requestedRef, {
                ...editableContent,
            })
            toast.success(`Updated information saved successfully`);
        } catch (error) {
            toast.error(`Error updating restaurant ${modalContent.id}: `)
            console.error(`Error updating restaurant ${modalContent.id}: `, error)
        }
    }

    const handlePreviewOpen = () => {
        dispatch(setModalRestaurant(editableContent));
        dispatch(setModalOpen(true));
        dispatch(setPreviewMode(true));
    }

    const addToInterestedAdminWishlist = async (restaurantId) => {
        const interestedAdminIds = ["m0Cpg8ukNaMO6ZIMgP0DbKBKjH53", "tIxCNXudhaQv73qiM0XyMIZjCh72"];

        for (const adminId of interestedAdminIds) {
            try {
                // Get a reference to the wishlist document
                const adminWishlistRef = doc(
                    db,
                    DB_PATHS.WISHLIST,
                    adminId
                );

                // Retrieve the current wishlist document data
                const wishlistSnap = await getDoc(adminWishlistRef);
                const wishlistData = wishlistSnap.data().wishlist;

                wishlistData.push(restaurantId)

                // Update the wishlist document in Firestore
                await setDoc(adminWishlistRef, {wishlist: wishlistData});

                console.log(`Restaurant ${restaurantId} added to wishlist of ${adminId}`);
            } catch (error) {
                console.error(`Error adding restaurant ${restaurantId} to wishlist of ${adminId}: `, error);
            }
        }
    };

    const handleSaveChanges = async () => {
        if (
            !editableContent.name ||
            !editableContent.address ||
            !editableContent.city ||
            !editableContent.latitude ||
            !editableContent.longitude ||
            !editableContent.menu_status ||
            !editableContent.placeId
        ) {
            toast.error("Please fill in all required fields")
            return
        }

        let placeData = await handleFetchPlaceData(editableContent.placeId);

        if (!placeData) {
            toast.error("Couldnt find palces data");
            return;
        }

        let postContent = {
            name: editableContent.name,
            address: editableContent.address,
            city: editableContent.city.toUpperCase(),
            latitude: parseFloat(editableContent.latitude),
            longitude: parseFloat(editableContent.longitude),
            menu_status: editableContent.menu_status,
            website: editableContent.website,
            reels: editableContent.reels || [],
            instagramProfileTag: editableContent.instagramProfileTag || "",
            tags: editableContent.tags,
            description: editableContent.description,
            placeId: editableContent.placeId || "",
            googleMapsUri: placeData?.googleMapsUri || '',
            currentOpeningHours: placeData?.currentOpeningHours?.weekdayDescriptions || [],
            priceLevel: placeData?.priceLevel || "",
            phoneNumber: placeData?.nationalPhoneNumber || "",
            rating: placeData?.rating || "",
            userRatingCount: placeData?.userRatingCount || "",
            shortFormattedAddress: placeData?.shortFormattedAddress || "",
            businessStatus: placeData?.businessStatus || "",
            websiteUri: placeData?.websiteUri || "",
        }

        if (typeof editableContent.reels === 'string') {
            postContent.reels = editableContent.reels.split(",")
        }

        const dataToHash = `${parseFloat(postContent.latitude).toFixed(
            5
        )},${parseFloat(postContent.longitude).toFixed(
            5
        )},${postContent.name.trim()}`
        const hash = CryptoJS.SHA256(dataToHash).toString(CryptoJS.enc.Hex)
        const docId = hash.substr(0, 16)

        try {
            await setDoc(
                doc(db, DB_PATHS.RESTAURANTS, docId),
                {
                    ...postContent,
                },
                {merge: true}
            )
            await deleteDoc(doc(db, DB_PATHS.REQUESTED, modalContent.id));
            toast.success(`Restaurant uploaded successfully`);
            refetchRestaurants();
            addToInterestedAdminWishlist(docId);
            closeModal();
        } catch (error) {
            toast.error(`Error uploading document ${docId}: `)
            console.error(`Error uploading document ${docId}: `, error)
        }
    }

    const refetchRestaurants = async () => {
        getDocs(collection(db, DB_PATHS.RESTAURANTS)).then((snapshot) => {
            const restaurantData = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            dispatch(setRestaurants(restaurantData));
            dispatch(setMarkers(restaurantData));
        });
    }

    const closeModal = () => {
        setEditableContent({});
        getPendingData();
        handleClose();
    }
    const convertToTitleCase = () => {
        const name = editableContent.name;
        const titleCaseName = name
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
        setEditableContent({...editableContent, name: titleCaseName});
    };


    const handleAddressToLatLng = async () => {
        try {
            // Assuming editableContent is a state variable
            const {address} = editableContent;

            // Make a POST request to your Firebase Cloud Function endpoint
            const response = await fetch('https://getlatlong-osuqmch7da-uc.a.run.app', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({address})
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch coordinates: ${response.status} - ${response.statusText}`);
            }

            const data = await response.json();

            // Update editableContent state with fetched latitude, longitude, and formatted address
            setEditableContent(prevState => ({
                ...prevState,
                address: data.formattedAddress,
                latitude: data.latitude,
                longitude: data.longitude
            }));
        } catch (error) {
            toast.error(`Error fetching coordinates: ${error.message}`);
            console.error("Error fetching coordinates:", error);
        }
    };

    return (
        <>
            <Modal open={open} onClose={closeModal}>
                <Box sx={style}>
                    <Typography variant="h6" component="h2" gutterBottom>
                        {editableContent?.name}
                    </Typography>
                    <IconButton
                        style={{position: "absolute", top: 10, right: 10}}
                        onClick={closeModal}
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography>Submitted by {editableContent?.userName}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Name"
                                required
                                fullWidth
                                value={editableContent?.name || ""}
                                onChange={(e) => handleInputChange(e, "name")}
                                InputProps={{
                                    endAdornment: (
                                        <Button onClick={convertToTitleCase}>
                                            <TitleIcon/>
                                        </Button>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Address"
                                fullWidth
                                required
                                value={editableContent?.address || ""}
                                onChange={(e) => handleInputChange(e, "address")}
                                InputProps={{
                                    endAdornment: (
                                        <Button onClick={handleAddressToLatLng} color="primary">Get
                                            Lat/Lng</Button>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Latitude"
                                fullWidth
                                required
                                value={editableContent?.latitude || ""}
                                onChange={(e) => handleInputChange(e, "latitude")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Longitude"
                                fullWidth
                                required
                                value={editableContent?.longitude || ""}
                                onChange={(e) => handleInputChange(e, "longitude")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="City"
                                fullWidth
                                required
                                value={editableContent?.city || ""}
                                onChange={(e) => handleInputChange(e, "city")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel id="menu-status-label">Menu Status</InputLabel>
                            <Select
                                labelId="menu-status-label"
                                required
                                value={editableContent?.menu_status || ""}
                                onChange={(e) => handleInputChange(e, "menu_status")}
                                fullWidth
                            >
                                {halalStates.map((status) => (
                                    <MenuItem key={status} value={status}>
                                        {status}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Description"
                                multiline
                                rows={4}
                                fullWidth
                                value={editableContent?.description || ""}
                                onChange={(e) => handleInputChange(e, "description")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Website"
                                fullWidth
                                value={editableContent?.website || ""}
                                onChange={(e) => handleInputChange(e, "website")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Instagram Handle"
                                fullWidth
                                value={editableContent?.instagramProfileTag || ""}
                                onChange={(e) => handleInputChange(e, "instagramProfileTag")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Reels"
                                fullWidth
                                value={editableContent?.reels || []}
                                onChange={(e) => {
                                    setEditableContent({
                                        ...editableContent,
                                        reels: [e.target.value],
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                id="tags-filled"
                                sx={{mt: "0.5rem"}}
                                options={all_tags.map((option) => option)}
                                freeSolo
                                value={editableContent?.tags || []}
                                onChange={(event, newValue) => {
                                    setEditableContent({
                                        ...editableContent,
                                        tags: newValue,
                                    });
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip variant="outlined" label={option} {...getTagProps({index})} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        multiline
                                        rows={1}
                                        variant="outlined"
                                        label="Tags"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Get Place ID from <Link
                                target="_blank"
                                href={"https://developers.google.com/maps/documentation/javascript/examples/places-placeid-finder"}>here</Link>
                            </Typography>
                            <TextField
                                label="Place ID"
                                required
                                fullWidth
                                value={editableContent?.placeId || ""}
                                onChange={(e) => handleInputChange(e, "placeId")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                onClick={handleSaveChanges}
                                variant="contained"
                                color="primary"
                                fullWidth
                            >
                                Add Restaurant
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                onClick={() => saveChanges()}
                                variant="contained"
                                color="info"
                                fullWidth
                            >
                                Save Changes
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                onClick={handlePreviewOpen}
                                variant="contained"
                                color="secondary"
                                fullWidth
                            >
                                Preview
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                onClick={handleDelete}
                                variant="contained"
                                startIcon={<DeleteIcon/>}
                                color="error"
                                fullWidth
                            >
                                DELETE
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    )
}
