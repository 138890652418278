import React, {useEffect, useRef, useState, useCallback} from "react";
import {styled} from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import {useDispatch, useSelector} from "react-redux";
import { debounce } from 'lodash';

import {SearchResults} from "./SearchResults";
import {setMarkers, setSearchedMarkers} from "../../redux/features/markers/markersSlice";
import {SearchResultPaper} from "./SearchResultPaper";
import {Chip} from "@mui/material";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {NearbyRestaurants} from "../NearbyRestaurants/NearbyRestaurants";
import Hidden from "@mui/material/Hidden";
import {filterRestaurants} from "./search-util";

const SearchContainer = styled("div")(({theme}) => ({
    position: "absolute",
    top: "calc(100% + 20px)",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 999,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "rgba(0, 0, 0, 0.8)", // Slightly transparent black
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    width: "auto",
    [theme.breakpoints.down("sm")]: {
        minWidth: "100%", // Set minWidth to 80% for mobile
    },
    [theme.breakpoints.up("md")]: {
        minWidth: "50%", // Set minWidth to 50% for other viewports
    },
}));


const SearchIconWrapper = styled("div")(({theme}) => ({
    marginRight: theme.spacing(1),
    color: "#ffffff", // White color
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: "#ffffff", // White color
    flex: 1,
    "& .MuiInputBase-input": {
        padding: theme.spacing(1),
        paddingLeft: `calc(1em + ${theme.spacing(2)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
    },
}));

const SpotlightOverlay = styled("div")({
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Transparent black
    zIndex: 998,
    pointerEvents: "none", // Clicks will pass through the overlay
});

export const Searchbar = ({visible, onHide, searchTerm, setSearchTerm}) => {
    const allRestaurants = useSelector(
        (state) => state.restaurantsReducer.restaurants
    );
    const nearbyRestaurants = useSelector(
        (state) => state.restaurantsReducer.nearbyRestaurants
    );
    const [searchResults, setSearchResults] = useState([]);
    const searchRef = useRef();
    const inputRef = useRef(null);
    const [hasUserLocation, setHasUserLocation] = useState(false);
    const infoModalOpen = useSelector(
        (state) => state.infoModalReducer.open
    );
    const dispatch = useDispatch();
    const [nearbyRestaurantsOpen, setNearbyRestaurantsOpen] = useState(false);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(() => {
                setHasUserLocation(true);
            });
        }
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedHandleSearch = useCallback(debounce((term) => {
        const restaurantsToUse = nearbyRestaurants.length > 0 ? nearbyRestaurants : allRestaurants;

        let searchString = term.toLowerCase().trim();
        const filteredResults = filterRestaurants(restaurantsToUse, searchString);
        setSearchResults(filteredResults);

        const enableMapFiltering = false; // TODO: Get this from Redux which is set by a toggle
        if (enableMapFiltering) {
            dispatch(setMarkers(filteredResults));
            if (term !== "") {
                dispatch(setSearchedMarkers(filteredResults));
            } else {
                dispatch(setSearchedMarkers(null));
            }
        }
    }, 300), [nearbyRestaurants, allRestaurants, dispatch]);

    const handleSearch = (event) => {
        debouncedHandleSearch(event);
    };

    const handleClickOutside = (event) => {
        if (searchRef.current && !searchRef.current.contains(event.target) && !infoModalOpen && !nearbyRestaurantsOpen) {
            setNearbyRestaurantsOpen(false);
            onHide();
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("touchstart", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);

        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [infoModalOpen, nearbyRestaurantsOpen]);

    useEffect(() => {
        if (visible && inputRef.current) {
            inputRef.current.focus();
        }
    }, [visible])

    const handleNewSearchTerm = (term) => {
        setSearchTerm(term);
        handleSearch(term);
    }

    return (
        <>
            {visible && (
                <div ref={searchRef}>
                    <SpotlightOverlay/>
                    <SearchContainer>
                        <SearchIconWrapper>
                            <SearchIcon/>
                        </SearchIconWrapper>
                        <StyledInputBase
                            inputRef={inputRef}
                            value={searchTerm}
                            onChange={(e) => {
                                handleNewSearchTerm(e.target.value);
                            }}
                            placeholder="Search…"
                            inputProps={{"aria-label": "search"}}
                        />
                        {searchTerm ? (
                            <SearchResults
                                searchResults={searchResults}
                                setSearchResults={setSearchResults}
                                showModal={infoModalOpen}
                            />
                        ) : <SearchResultPaper>
                            <Container
                                style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', padding: '10px'}}>
                                <Typography variant="body1" style={{marginRight: '10px'}}>Ex:</Typography>
                                <Chip label={"fried chicken"} variant="outlined" size="small"
                                      onClick={() => handleNewSearchTerm("fried chicken")}/>
                                <Chip label={"fine dining"} variant="outlined" size="small"
                                      onClick={() => handleNewSearchTerm("fine dining")}/>
                                <Chip label={"bangladeshi"} variant="outlined" size="small"
                                      onClick={() => handleNewSearchTerm("bangladeshi")}/>
                                <Chip label={"bbq"} variant="outlined" size="small"
                                      onClick={() => handleNewSearchTerm("bbq")}/>
                                <Chip label={"full halal"} variant="outlined" size="small"
                                      onClick={() => handleNewSearchTerm("full halal")}/>
                                <Box sx={{flexGrow: 1}}></Box>
                                {/* Show the Nearby Restaurants button only on larger screens */}
                                <Hidden mdDown>
                                    {hasUserLocation ? (
                                        <Button
                                            size={'small'}
                                            onClick={() => {
                                                setNearbyRestaurantsOpen(true);
                                            }}
                                            style={{
                                                borderRadius: 20,
                                                color: '#000',
                                                backgroundColor: '#00C2FF',
                                                textTransform: 'none'
                                            }}
                                            sx={{marginTop: {xs: '1rem', lg: 0}}}
                                            variant='contained'>
                                            Nearby Restaurants
                                        </Button>
                                    ) : null}
                                </Hidden>
                            </Container>
                        </SearchResultPaper>
                        }
                    </SearchContainer>
                    {nearbyRestaurantsOpen && (
                        <NearbyRestaurants
                            open={nearbyRestaurantsOpen}
                            handleClose={() => {
                                setNearbyRestaurantsOpen(false);
                            }}
                        />
                    )}
                </div>
            )}
        </>
    );
};
