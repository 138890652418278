import Fuse from 'fuse.js';

export function filterRestaurants(restaurantsToUse, searchString) {
    // Initialize Fuse.js with search options
    const fuse = new Fuse(restaurantsToUse, {
        keys: [
            'name',
            'address',
            'menu_status',
            'tags',
        ],
        threshold: 0.3,  // Adjust threshold to control fuzziness (lower = stricter)
        includeScore: true,  // Optionally include a score for ranking
    });

    const searchLower = searchString.toLowerCase();

    // Perform fuzzy search using Fuse.js
    const fuzzyResults = fuse.search(searchLower);

    // Extract the matched restaurants (fuse.search returns { item, score } objects)
    const matchedRestaurants = fuzzyResults.map(result => result.item);

    // Filter for unique restaurants by name and address
    const uniqueRestaurants = matchedRestaurants.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.name === value.name && t.address === value.address
            ))
    );

    // Sort restaurants by distance: within 50km comes first
    uniqueRestaurants.sort((a, b) => {
        const aWithin50km = a.distance <= 50;
        const bWithin50km = b.distance <= 50;

        // If both are within 50km or both are outside 50km, retain the original order
        if (aWithin50km === bWithin50km) {
            return 0;
        }

        // Move those within 50km to the front
        return aWithin50km ? -1 : 1;
    });

    return uniqueRestaurants;
}
